<template>
	<div class="suppierDetail">
		<el-form
			:model="ruleForm"
			:rules="rules"
			ref="ruleForm"
			label-width="150px"
			style="margin-bottom:80px;"
			v-loading="loading"
		>
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>基本信息</span>
				</div>
				<div class="content">
					<!--  -->
					<el-form-item label="优惠券名称：" prop="couponName">
						<el-input
							v-model="ruleForm.couponName"
							:disabled="currentEditStatus==0"
							placeholder="最多输入10个字"
							style="width:200px"
							maxlength="10"
						></el-input>
					</el-form-item>

					<el-form-item label="优惠券总量：" prop="num">
						<el-input
							:disabled="currentEditStatus==0"
							v-model="ruleForm.num"
							placeholder="最多100000000张"
							onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
							onblur="this.v();if (value==0||value=='-'){value =''}"
							style="width:200px"
						></el-input>
						<div class="remarks">优惠券总量设置后，只可增加，不可减少</div>
					</el-form-item>
					<!-- 适用商品 -->
					<el-form-item label="适用商品：" required>
						<!-- <el-radio-group  @change="changeRangeType" > -->
						<div class="radio">
							<el-radio
								:disabled="currentEditStatus==0"
								@change="changeRangeType"
								v-model="ruleForm.applyType"
								:label="0"
							>全部商品适用</el-radio>
						</div>
						<div class="radio">
							<el-radio
								:disabled="currentEditStatus==0"
								@change="changeRangeType"
								v-model="ruleForm.applyType"
								:label="1"
							>指定商品适用</el-radio>
							<span
								v-if="ruleForm.applyType==1&&currentEditStatus!=0"
								style="color:#409EFF;cursor: pointer;"
								@click="handleSelectProDialog"
							>选择商品</span>
						</div>
						<div class="radio">
							<el-radio
								:disabled="currentEditStatus==0"
								@change="changeRangeType"
								v-model="ruleForm.applyType"
								:label="2"
							>指定商品不适用</el-radio>
							<span
								v-if="ruleForm.applyType==2&&currentEditStatus!=0"
								style="color:#409EFF;cursor: pointer;"
								@click="handleSelectProDialog"
							>选择商品</span>
						</div>
					</el-form-item>
					<!-- list -->
					<el-form-item v-if="ruleForm.applyType==1">
						<el-table border :data="ruleForm.pageActivityCouponProductList " style="width: 100%">
							<el-table-column label="商品" width="300px">
								<template slot-scope="scope">
									<div class="dialog-name-content">
										<img :src="imgUrl+scope.row.thumbImgUrl" />
										<div class="right">
											<div class="name">
												<pre
													style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;"
												>{{scope.row.goodsName}}</pre>
											</div>
											<span>{{scope.row.Remark}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="salePrice" label="价格"></el-table-column>
							<el-table-column prop="totalStock" label="库存"></el-table-column>
							<el-table-column label="操作" v-if="currentEditStatus!=0">
								<template slot-scope="scope">
									<el-button
										style="color:#F56C6C;"
										@click="deleteSortPro(scope.row.id)"
										type="text"
										size="small"
									>删除</el-button>
								</template>
							</el-table-column>
						</el-table>

						<el-pagination
							v-if="page.total"
							style="margin-top:20px;float:right;"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="page.current"
							:page-sizes="[5,10,20]"
							:page-size="page.size"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.total"
						></el-pagination>
					</el-form-item>
					<el-form-item v-if="ruleForm.applyType==2">
						<el-table border :data="ruleForm.pageActivityCouponProductList " style="width: 100%">
							<el-table-column label="商品" width="300px">
								<template slot-scope="scope">
									<div class="dialog-name-content">
										<img :src="imgUrl+scope.row.thumbImgUrl" />
										<div class="right">
											<div class="name">{{scope.row.goodsName}}</div>
											<span>{{scope.row.Remark}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="salePrice" label="价格"></el-table-column>
							<el-table-column prop="totalStock" label="库存"></el-table-column>
							<el-table-column label="操作" v-if="currentEditStatus!=0">
								<template slot-scope="scope">
									<el-button
										style="color:#F56C6C;"
										@click="deleteSortPro(scope.row.id)"
										type="text"
										size="small"
									>删除</el-button>
								</template>
							</el-table-column>
						</el-table>

						<el-pagination
							v-if="page.total"
							style="margin-top:20px;float:right;"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="page.current"
							:page-sizes="[5,10,20]"
							:page-size="page.size"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.total"
						></el-pagination>
					</el-form-item>
					<!-- 适用门槛 -->
					<el-form-item label="适用门槛：" required>
						<el-radio-group
							:disabled="currentEditStatus==0"
							v-model="shold"
							style="margin-top:10px;"
							@change="changeRangeRadio"
						>
							<el-radio :label="0" class="radio-group">无门槛使用</el-radio>
							<br />
							<el-radio :label="1" class="radio-group" style="margin-top:10px;">
								消费满
								<el-form-item
									prop="sholdMoney"
									style="margin:0 10px;"
									ref="editDisRangeValue"
									class="el-form-date-pick"
								>
									<el-input
										style="width:200px;"
										type="number"
										@change="checkPriceFullMoney"
										:disabled="shold==0||currentEditStatus==0"
										v-model="ruleForm.sholdMoney"
									></el-input>
								</el-form-item>元可用
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<!-- 优惠券面额 -->

					<el-form-item label="优惠券面额：" v-if="currentEditType==0" prop="faceMoney">
						减
						<el-input
							style="margin:0 10px;width:200px;"
							type="number"
							@change="checkPriceMinMoney"
							:disabled="this.currentEditId!=''"
							v-model="ruleForm.faceMoney"
						></el-input>元
					</el-form-item>
					<el-form-item label="优惠券面额：" v-if="currentEditType==2" prop="faceDiscount">
						打
						<el-input
							style="margin:0 10px;width:200px;"
							:max="9.9"
							:disabled="this.currentEditId!=''"
							v-model="ruleForm.faceDiscount"
							@change="handleChangeDicsount"
						></el-input>折
					</el-form-item>
					<el-form-item label="优惠券面额：" required v-if="currentEditType==1">
						减
						<el-form-item prop="faceMoney" style="display:inline-block;margin:0 10px;">
							<el-input
								type="number"
								:max="9.9"
								@change="checkPriceMinMoney"
								:disabled="this.currentEditId!=''"
								v-model="ruleForm.faceMoney"
							></el-input>
						</el-form-item>元
						<span style="margin:0 5px">~</span>
						<el-form-item prop="MaxMoney" style="display:inline-block;margin:0 10px;">
							<el-input
								type="number"
								:max="9.9"
								@change="checkPriceMaxMoney"
								:disabled="this.currentEditId!=''"
								v-model="ruleForm.MaxMoney"
							></el-input>
						</el-form-item>元
					</el-form-item>
					<el-form-item label="优惠券是否展示：" required :inline-message="true" prop="isAllowShow">
						<el-switch v-model="ruleForm.isAllowShow" active-color="#13ce66" inactive-color="#999"></el-switch>
						<!-- <div class="textColor">默认允许退货；关闭后，将对会员隐藏退货按钮</div> -->
					</el-form-item>
				</div>
			</el-card>
			<!-- card2 -->
			<el-card class="box-card" style="margin-top:30px">
				<div slot="header" class="clearfix">
					<span>领取和使用</span>
				</div>
				<div class="content">
					<!-- 使用期限 -->
					<el-form-item label="使用期限" required>
						<el-radio-group
							:disabled="this.currentEditId!=''"
							v-model="ruleForm.deadType"
							class="radio-group"
							@change="changeDeadlineRadio"
						>
							<el-radio class="radio-group" :label="1">
								<el-form-item prop="valueTime" ref="editDisDeadlineStart" class="el-form-date-pick">
									<el-date-picker
										v-model="ruleForm.valueTime"
										type="datetimerange"
										:disabled="ruleForm.deadType!=1||this.currentEditId!=''"
										range-separator="~"
										start-placeholder="开始日期"
										end-placeholder="结束日期"
										value-format="yyyy-MM-dd HH:mm:ss"
										@change="timeChange"
									></el-date-picker>
								</el-form-item>
							</el-radio>
							<br />
							<el-radio class="radio-group" :label="2">
								领取当日起
								<el-form-item
									prop="onDay"
									style="margin:10px 10px 0px 10px;"
									ref="editDisDeadlineValue"
									class="el-form-date-pick"
								>
									<el-input
										style="margin-top:10px;width:200px"
										:disabled="ruleForm.deadType!=2||this.currentEditId!=''"
										v-model="ruleForm.onDay"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
										onblur="this.v();if (value>100000000 || value==0||value=='-'){value =''}"
									></el-input>
								</el-form-item>天内使用
							</el-radio>
							<br />
							<el-radio class="radio-group" :label="3">
								领取次日起
								<el-form-item
									prop="nextDay"
									style="margin:10px 10px 0px 10px;"
									ref="editDisDeadlineNextValue"
									class="el-form-date-pick"
								>
									<el-input
										style="margin-top:10px;width:200px"
										:disabled="ruleForm.deadType!=3||this.currentEditId!=''"
										v-model="ruleForm.nextDay"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
										onblur="this.v();if (value>100000000 || value==0||value=='-'){value =''}"
									></el-input>
								</el-form-item>天内使用
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<!-- 使用说明 -->
					<el-form-item label="使用说明:">
						<!-- :disabled='this.currentEditId!=""' -->
						<el-input
							:disabled="currentEditStatus==0"
							v-model="ruleForm.text"
							type="textarea"
							maxlength="300"
							:rows="5"
							style="width: 400px;"
						></el-input>
					</el-form-item>
					<!-- 领取限制 -->
					<el-form-item label="领取限制:" required>
						<el-radio-group
							:disabled="this.currentEditId!=''"
							v-model="receiveLimitRadio"
							style="margin-top:10px;"
							@change="changeRestrictionRadio"
						>
							<el-radio :label="0" class="radio-group">不限制次数</el-radio>
							<br />
							<el-radio :label="1" class="radio-group" style="margin-top:10px;">
								每人限领
								<el-form-item
									prop="getNum"
									style="margin:0 10px;"
									ref="editDisRestrictionValue"
									class="el-form-date-pick"
								>
									<el-input
										onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
										onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
										maxlength="30"
										style="width:200px;"
										:disabled="receiveLimitRadio==1&&this.currentEditId!=''"
										v-model="ruleForm.getNum"
									></el-input>
								</el-form-item>次
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="使用人群：" prop="useLimit">
						<el-select style="width: 424px" multiple v-model="ruleForm.useLimit" placeholder="请选择使用人群">
							<el-option v-for="item in LEVELLIST" :key="item.pusherLevel" :label="item.pusherLabel" :value="item.pusherLevel"></el-option>
							<el-option label="粉丝" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-card>

			<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
				<select-produce
					api="activitycouponChoseproductlist"
					:couponData="couponData"
					:params="['goodsName', 'goodsCode']"
					:selectedData="ruleForm.noassign"
					@getSelectList="getSelectList"
					:visible.sync="selectProDialog"
					v-if="selectProDialog"
				></select-produce>
			</el-dialog>

			<!-- </el-dialog> -->
			<div class="footer" v-if="currentEditStatus!=0">
				<el-button style="width:240px;" @click="suppilerCansel">取消</el-button>
				<el-button
					style="width:240px;"
					type="primary"
					@click="saveSupiler('ruleForm')"
					:loading="loading"
				>保存</el-button>
			</div>
			<div class="footer" v-if="currentEditStatus==0">
				<el-button style="width:240px;" @click="suppilerCansel">关闭</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	import config from '@/config/index'
	import selectProduce from './selectMultiPro'
	import apiList from '@/api/other'

	export default {
	components: {
		selectProduce
	},
	data () {
		var checkCouponName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入优惠券名称'));
			} else if (!value.trim()) {
				return callback(new Error('请输入优惠券名称'));
			} else {
				return callback()
			}
		};
		var checkAllCount = (rule, value, callback) => {
			if (value > 100000000) {
				this.ruleForm.num = ''
				return callback(new Error('优惠券总量超出限制，请重新设置'));
			} else {
				if (!value) {
					return callback(new Error('请输入优惠券总量'));
				} else {
					return callback();
				}
			}
		};
		var checkFullMoney = (rule, value, callback) => {
			if (this.shold == 1 && value === '') {
				return callback(new Error('请设置使用门槛'));
			} else if (this.shold == 1 && value === 0) {
				return callback(new Error('优惠门槛金额需大于0，最多2位小数'));
			} else {
				return callback();
			}
		};
		var checkValidDiscount = (rule, value, callback) => {
			if (value === 0 || value > 10 || value < 1) {
				return callback(new Error('折扣请设置在1-9.9折之间，最多1位小数'));
			} else {
				if (!value) {
					return callback(new Error('请设置优惠券面值'));
				} else {
					return callback();
				}
			}
		};
		var checkValidMinMoney = (rule, value, callback) => {
			if (this.currentEditType == 0) {
				if (value == 0) {
					this.ruleForm.faceMoney = ''
					return callback(new Error('优惠券面值大于0，最多2位小数'));
				} else {
					if (!value) {
						return callback(new Error('请设置优惠券面值'));
					} else {
						return callback();
					}
				}
			} else {
				if (value == 0) {
					this.ruleForm.faceMoney = ''
					return callback(new Error('随机金额大于0，最多2位小数'));
				} else {
					if (!value) {
						return callback(new Error('请设置随机金额范围'));
					} else {
						return callback();
					}
				}
			}
		};
		var checkValidMaxMoney = (rule, value, callback) => {
			if (value == 0) {
				this.ruleForm.MaxMoney = ''
				return callback(new Error('随机金额大于0，最多2位小数'));
			} else {
				if (!value) {
					return callback(new Error('请设置随机金额范围'));
				} else {
					return callback();
				}
			}
		};
		var checkValidStartTime = (rule, value, callback) => {
			if (this.ruleForm.deadType == 1 && (!value)) {
				callback(new Error('请输入时间期限'));
			} else {
				return callback()
			}
		};
		var checkValidEndTime = (rule, value, callback) => {
			if (this.ruleForm.deadType == 1 && (!value)) {
				callback(new Error('请输入时间期限'));
			} else {
				return callback()
			}
		};
		var checkValidTimeToday = (rule, value, callback) => {
			if (this.ruleForm.deadType == 2 && (!value)) {
				callback(new Error('请输入时间期限'));
			} else {
				return callback()
			}
		};
		var checkValidTimeNext = (rule, value, callback) => {
			if (this.ruleForm.deadType == 3 && (!value)) {
				callback(new Error('请输入时间期限'));
			} else {
				return callback()
			}
		};
		var checkReceiveLimit = (rule, value, callback) => {
			if (this.receiveLimitRadio == 1 && (!value || value == '' || value == '0')) {
				callback(new Error('请输入领取限制'));
			} else {
				callback();
			}
		};
		var checkValidTime = (rule, value, callback) => {
			if (this.ruleForm.deadType == 1 && ((!value) || (!value.length))) {
				callback(new Error('请输入时间期限'));
			} else {
				return callback()
			}
		};

		return {
			baseAPI: config.BASE_URL,
			imgUrl: config.IMG_BASE,
			loading: false,
			ruleForm: {
				couponName: '',//优惠券名称
				num: '',//数量
				applyType: 0,//适用商品类型0：全部商品适用1：指定商品适用2：指定商品不适用
				noassign: [],//不适用的商品id[1,2,3]
				sholdMoney: '',//消费满减金额
				deadType: 1,//使用类型 1:时间段2：领取当日起3：领取次日起
				faceDiscount: '',//优惠券面额折扣 9.5
				begTime: '',
				endTime: '',
				onDay: '',//领取当日起
				nextDay: '',//领取次日起
				deadlineNextValue: '',
				text: '',//使用说明
				restriction: '1',
				restrictionValue: '',
				getType: 0,//领取限制类型0:不限次数1：限领
				useLimit: '',//使用人群
				noassignObject: [],
				assignObject: [],
				valueTime: [],
				isAllowShow:false
			},
			ProductList: [],
			shold: 0,//适用门槛0：无门槛使用1：消费满减适用门槛0：无门槛使用1：消费满减
			receiveLimitRadio: 0,
			currentCount: '',
			rules: {
				couponName: [{
					required: true,
					trigger: 'blur',
					validator: checkCouponName
				}],
				num: [{
					trigger: 'blur',
					required: true,
					validator: checkAllCount
				}],
				sholdMoney: [{
					trigger: 'blur',
					validator: checkFullMoney
				}],
				faceDiscount: [{
					// required: true,
					// message: '请设置优惠券面值',
					// trigger: 'blur'
					required: true,
					trigger: 'blur',
					validator: checkValidDiscount
				}],
				faceMoney: [{
					trigger: 'blur',
					required: true,
					validator: checkValidMinMoney
				}],
				MaxMoney: [{
					trigger: 'blur',
					required: true,
					validator: checkValidMaxMoney
				}],
				begTime: [{
					trigger: 'blur',
					validator: checkValidStartTime
				}],
				endTime: [{
					trigger: 'blur',
					validator: checkValidEndTime
				}],
				onDay: [{
					trigger: 'blur',
					validator: checkValidTimeToday
				}],
				nextDay: [{
					trigger: 'blur',
					validator: checkValidTimeNext
				}],
				restriction: [{
					required: true,
					message: '请选择领取限制',
					trigger: 'change'
				}],
				// restrictionValue: [{
				//   trigger: 'blur',
				//   validator:checkRestrictionValue
				// }],
				getNum: [{
					trigger: 'blur',
					validator: checkReceiveLimit
				}],
				useLimit: [
				    {required: true, message: '请选择使用人群', trigger: 'blur'}
				],
				valueTime: [{
					trigger: 'blur',
					validator: checkValidTime
				}],
			},
			oriData: {
				noassign: [],
				sholdMoney: 0
			},
			page: {
				total: 0,
				current: 1,
				size: 5
			},
			assign: [],
			selectProDialog: false,
			multipleSelection: [],
			currentEditId: '',
			currentEditType: null,
			currentEditStatus: 0,
			currentEditCopyId: '',
			couponData: {
				id: '',
				range: 0
			},
		}
	},
	created () {
		this.currentEditId = this.$route.query.id || ''
		this.currentEditType = +this.$route.query.type
		this.currentEditStatus = this.$route.query.status
		this.currentEditCopyId = this.$route.query.copyId || ''
		if (this.currentEditCopyId != '') {
			this.currentEditId = this.currentEditCopyId
		}
		if (this.currentEditId != '') {
			this.getData()
		}
		//传值给选择商品的组件
		this.couponData.id = this.$route.query.id

	},
	computed: {
		minOptions: function () {
			let limitTime = this.ruleForm.begTime
			let d = new Date();
			let day = d.getDate();
			let month = d.getMonth() + 1;
			let year = d.getFullYear();
			let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
			return {
				disabledDate (time) {
					if (limitTime) {
						return time < new Date(new Date(limitTime).toLocaleDateString())
					} else {
						return time < new Date(currentDate)
					}
				},
			}
		},
		//日期选择器限制选择
		maxOptions: function () {
			let limitTime = this.ruleForm.endTime
			let d = new Date();
			let day = d.getDate();
			let month = d.getMonth() + 1;
			let year = d.getFullYear();
			let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
			return {
				disabledDate (time) {
					if (limitTime) {
						return (
							time > new Date(new Date(limitTime).toLocaleDateString()) ||
							time < new Date(currentDate)
						)
					} else {
						return time < new Date(currentDate)
					}
				}
			}
		}
	},
	methods: {
		handleChangeDicsount (value) {
			value = (value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
			if (value > 9.9) {
				value = null
			} else if (value < 1) {
				value = null
			}
			this.ruleForm.faceDiscount = value
		},
		checkPriceMinMoney (value) {
			value = value.match(/^\d*(\.?\d{0,2})/g)[0] ? value.match(/^\d*(\.?\d{0,2})/g)[0] : ''
			this.ruleForm.faceMoney = value
		},
		checkPriceMaxMoney (value) {
			value = value.match(/^\d*(\.?\d{0,2})/g)[0] ? value.match(/^\d*(\.?\d{0,2})/g)[0] : ''
			this.ruleForm.MaxMoney = value
		},
		checkPriceFullMoney (value) {
			value = value.match(/^\d*(\.?\d{0,2})/g)[0] ? value.match(/^\d*(\.?\d{0,2})/g)[0] : ''
			this.ruleForm.sholdMoney = value
		},
		changeRangeRadio (e) {
			if (e == 0) {
				this.ruleForm.sholdMoney = ''
				this.$refs['editDisRangeValue'].clearValidate()
			}
		},
		changeDeadlineRadio (e) {
			if (e == 0) {
				this.ruleForm.nextDay = ''
				this.ruleForm.onDay = ''
				this.$refs['editDisDeadlineNextValue'].clearValidate()
				this.$refs['editDisDeadlineValue'].clearValidate()
			} else if (e == 1) {
				this.ruleForm.endTime = ''
				this.ruleForm.begTime = ''
				this.ruleForm.nextDay = ''
				this.ruleForm.valueTime = [];
				this.$refs['editDisDeadlineStart'].clearValidate()
				// this.$refs['editDisDeadlineEnd'].clearValidate()
				this.$refs['editDisDeadlineNextValue'].clearValidate()
			} else {
				this.ruleForm.endTime = ''
				this.ruleForm.begTime = ''
				this.ruleForm.valueTime = [];
				this.ruleForm.onDay = ''
				this.$refs['editDisDeadlineStart'].clearValidate()
				// this.$refs['editDisDeadlineEnd'].clearValidate()
				this.$refs['editDisDeadlineValue'].clearValidate()
			}
		},
		changeRangeType (e) {
			this.couponData.range = e
			this.ruleForm.noassign = []
			this.ruleForm.pageActivityCouponProductList = []
			this.page = {
				total: 0,
				current: 1,
				size: 5
			},
				this.$forceUpdate()
		},
		changeRestrictionRadio (e) {
			this.ruleForm.getType = e;
			if (e == 0) {
				this.ruleForm.getNum = ''
				this.$refs['editDisRestrictionValue'].clearValidate()
			}
		},
		//数据处理
		initForm () {
			this.ruleForm.endTime = (this.ruleForm.endTime == "2000-01-01 00:00:00") ? '' : this.ruleForm.endTime
			this.ruleForm.begTime = (this.ruleForm.begTime == "2000-01-01 00:00:00") ? '' : this.ruleForm.begTime
			this.shold = +this.ruleForm.shold == 0 ? 0 : 1;
			this.ruleForm.onDay = (this.ruleForm.onDay == 0) ? '' : this.ruleForm.onDay;
			this.ruleForm.nextDay = (this.ruleForm.nextDay == 0) ? '' : this.ruleForm.nextDay;
			this.receiveLimitRadio = +this.ruleForm.getType;
			this.ruleForm.getNum = (this.ruleForm.getNum == 0) ? '' : this.ruleForm.getNum
			this.couponData.range = this.ruleForm.applyType
			this.ruleForm.faceDiscount = (this.ruleForm.faceDiscount == 0) ? '' : +this.ruleForm.faceDiscount / 10;
			this.ruleForm.sholdMoney = (this.ruleForm.sholdMoney == 0) ? '' : this.ruleForm.sholdMoney;
			//商品分页
			let pagination = [];
			if (this.ruleForm.applyType == 1) {
				this.page.total = this.ruleForm.assignObject.length;
				pagination = this.ruleForm.assignObject.slice(0, 5);
			} else if (this.ruleForm.applyType == 2) {
				this.page.total = this.ruleForm.noassignObject.length;
				pagination = this.ruleForm.noassignObject.slice(0, 5);
			}

			this.ruleForm.pageActivityCouponProductList = pagination
			//如果是复制，不复制期限，由于复制是根据id值获取数据之后复制数据，需要将id值改为空
			if (this.currentEditCopyId != '') {
				this.currentEditId = ''
				this.ruleForm.endTime = ''
				this.ruleForm.begTime = ''
				this.ruleForm.onDay = ''
				this.ruleForm.nextDay = ''
				this.ruleForm.deadType = 1
			}
			this.$forceUpdate();
		},
		// 编辑时初始数据
		async getData () {
			this.loading = true;
			try {
				let result = await apiList.activitycouponActivitycoupondetail({ id: this.currentEditId });
				if (result.success) {
					result.data.isAllowShow = !!result.data.isShow;
					let params = result.data;
					params.num = result.data.totalNum;
					if (!this.currentEditCopyId && params.begTime && params.endTime) {
						params.valueTime = [params.begTime, params.endTime];
					}
					params.assignObject = result.data.goodsVOList;
					params.noassignObject = result.data.goodsVOList;
					params.noassign = result.data.goodsVOList ? result.data.goodsVOList : [];
					params.useLimit = result.data.useCrowd ? result.data.useCrowd.split(",").map(Number) : null;
					this.ruleForm = {...params};
					this.oriData.noassign = result.data.goodsVOList ? result.data.goodsVOList : [];
					this.oriData.sholdMoney = this.ruleForm.sholdMoney;
					this.initForm()
				} else {
					this.$message({ showClose: true, type: 'error', message: result.alertMsg });
				}
			} catch (error) {
				this.loading = false;
			} finally {
				this.loading = false;
			}
		},
		// 取消
		suppilerCansel () {
			this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
				(item) => {
					return item.path != this.$route.path;
				}
			);
			this.$router.push({
				path: '/market/coupon/index'
			});
		},
		async supilerData () {
			this.loading = true;
			try {
				let data = {
					id: this.currentEditId,
					type: this.currentEditType,
					couponName: this.ruleForm.couponName,
					num: this.ruleForm.num,
					applyType: this.ruleForm.applyType,
					shold: this.shold,
					sholdMoney: this.ruleForm.sholdMoney,
					faceMoney: this.ruleForm.faceMoney,
					MaxMoney: this.ruleForm.MaxMoney,
					faceDiscount: this.ruleForm.faceDiscount * 10,
					deadType: this.ruleForm.deadType,
					onDay: this.ruleForm.onDay,
					nextDay: this.ruleForm.nextDay,
					begTime: this.ruleForm.begTime,
					endTime: this.ruleForm.endTime,
					getNum: this.ruleForm.getNum,
					text: this.ruleForm.text,
					getType: this.ruleForm.getType,
					assign: [],
					noassign: [],
					useCrowd: this.ruleForm.useLimit.toString(),
					isShow:this.ruleForm.isAllowShow ? 1 : 0,
				};
				let ids = [];
				this.ruleForm.noassign.map(item => {
					ids.push(item.id);
					return item;
				})
				if (this.ruleForm.applyType == 1) {
					data.assign = ids;
				}
				if (this.ruleForm.applyType == 2) {
					data.noassign = ids;
				}
				console.log("---------", data);
				let result = null;
				if (this.currentEditId != '') {
					//编辑卡券接口
					result = await apiList.activitycouponActivitycouponupdate(data);
				} else {
					//添加卡券接口
					result = await apiList.activitycouponActivitycouponedit(data);
				}

				if (result.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功'
					});
					this.suppilerCansel();
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: result.alertMsg
					});
				}
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		// 保存
		saveSupiler (ruleForm) {
			this.$refs[ruleForm].validate(async (valid) => {
				if (valid) {
					let length = this.ruleForm.noassign.length
					if (this.ruleForm.applyType != 0 && length == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请选择商品'
						});
					} else {
						//若修改适用商品或使用门槛
						let index = (this.currentEditId != '') && ((this.oriData.noassign != this.ruleForm.noassign) ||
							(this.oriData.sholdMoney != this.ruleForm.sholdMoney))
						if (index) {
							this.$confirm('优惠券适用商品或使用门槛修改后，已经发放的优惠券也会同时更新为最新保存的数据，请确认您已了解修改已领取优惠券信息带来的权益变化及损失情况再进行修改。请提前告知消费者。', '提示', {
								confirmButtonText: '我已了解，确认修改',
								cancelButtonText: '关闭',
								type: 'warning'
							}).then(() => {
								this.supilerData()
							}).catch(() => {
								this.$message({
									showClose: true,
									type: 'info',
									message: '已取消修改'
								});
							})
						} else {
							//若没有
							this.supilerData()
						}
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},

		handleSelectProDialog () {
			this.selectProDialog = true
		},
		// 删除指定商品
		deleteSortPro (id) {
			this.ruleForm.noassign = this.ruleForm.noassign.filter(t => {
				if (t.id != id) {
					return t
				}
			})
			this.assign = this.assign.filter(t => {
				if (t != id) {
					return t
				}
			})
			if ((this.ruleForm.noassign.length) % this.page.size == 0 && this.page.current > 1) {
				this.page.current = this.page.current - 1;
			}
			this.paginationPro()
		},
		//获取选择商品的列表
		getSelectList (data) {
			this.assign = []
			this.ruleForm.noassign = data
			this.selectProDialog = false;
			data.forEach(t => {
				this.assign.push(t.id)
			});
			this.paginationPro()

		},
		//商品分页
		paginationPro () {
			this.page.total = this.ruleForm.noassign.length;
			let start = (this.page.current - 1) * this.page.size
			let end = start + this.page.size
			let pagination = this.ruleForm.noassign.slice(start, end);
			this.ruleForm.pageActivityCouponProductList = pagination;
			this.$forceUpdate()
		},
		handleSizeChange (val) {
			this.page.size = val
			this.paginationPro()
		},
		handleCurrentChange (val) {
			this.page.current = val
			this.paginationPro()
		},
		timeChange () {
			if (this.ruleForm.valueTime) {
				var datetime = this.ruleForm.valueTime[0];
				var datetime2 = this.ruleForm.valueTime[1];
				if (datetime != "" && datetime2 != "" && datetime == datetime2) {
					this.$message({
						type: 'error',
						showClose: true,
						message: "开始时间和结束时间不能相同"
					})
					this.ruleForm.valueTime = [];
					this.ruleForm.begTime = '';
					this.ruleForm.endTime = '';
				} else {
					this.ruleForm.begTime = datetime;
					this.ruleForm.endTime = datetime2;
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.suppierDetail {
	// margin: 30px;
	padding: 10px;

	.remarks {
		color: #bbb;
		font-size: 14px;
	}

	.parent-box {
		position: relative;

		.close-bosx {
			position: absolute;
			right: -5px;
			top: -13px;
			font-size: 18px;
		}
	}

	.markRed {
		position: absolute;
		left: -94px;
		top: 5px;
		color: red;
		font-size: 18px;
	}
}

.table-container {
	position: relative;
	overflow: hidden;
}

.dialog-name-content {
	display: flex;
	flex-direction: row;

	img {
		width: 60px;
		height: 60px;
		object-fit: cover;
		margin-right: 10px;
	}

	.name {
		width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	span {
		color: #e51c23;
		font-size: 12px;
	}
}

.radio-group {
	// display: block;
	.el-form-date-pick {
		display: inline-block;
	}
}

.footer {
	box-shadow: 13px 1px 6px #999;
	line-height: 80px;
	background-color: #fff;
	position: fixed;
	bottom: 0px;
	width: 100%;
	text-align: center;
}

.ori-input {
	height: 36px !important;
	width: 200px !important;
	padding: 0 5px;
	line-height: 32px;
	border: 1px solid #ddd;
	border-radius: 5px;
	// margin:0 10px;
}

input:disabled {
	background-color: #f5f7fa;
	border-color: #e4e7ed;
	color: #c0c4cc;
	padding: 0px 10px;
}

/deep/input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

/deep/.number__input {
	border: 1px solid #ccc;
}
</style>
